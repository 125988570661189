<template>
  <div class="header_global">
    <!-- 页头 -->
    <van-nav-bar title="顾客评价" left-arrow @click-left="onClickLeft" />
    <!-- 总评 -->
    <div class="mainbody">
      <van-row class="mainbody_first">
        <van-col>
          <span class="digital">{{ evaluate.allAppraise }}</span>
        </van-col>
        <van-col class="mainbody_second">
          总体评价
          <br />
          <van-rate
            v-model="evaluate.allAppraise"
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
            allow-half
            size="10px"
            readonly
          />
        </van-col>
        <van-col class="mainbody_third">
          口味
          <br />
          {{ evaluate.allAppraiseFlavor }}
        </van-col>
        <van-col class="mainbody_fourth">
          包装
          <br />
          {{ evaluate.allAppraisePack }}
        </van-col>
        <van-col class="mainbody_fifth">
          配送满意度
          <br />
          {{ evaluate.allAppraiseSend }}
        </van-col>
      </van-row>
    </div>
    <!-- 评论条数 -->
    <div class="middle_comment">
      <span class="comments">评论{{arr.length}}条</span>
    </div>

    <!-- 回复评论 -->
    <div class="middle" v-for="item in evaluate.allOrderGoods" :key="item.id">
      <van-row>
        <van-col class="img_first">
          <van-image
            round
            width="40px"
            height="40px"
            :src="item.appraiseHeader"
          />
        </van-col>
        <van-col class="anonymous_first">
          {{item.appraiseName}}
          <br />
          <van-rate
          v-model="item.allEvaluate"
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
            allow-half
            size="5px"
            readonly
          />
          <br />
          <span class="date">{{item.createTime.split(" ")[0]}}</span>
        </van-col>
        <van-col class="taste_first">
          口味<br />
          {{item.appraiseFlavor}}
        </van-col>

        <van-col class="pack_first">
          包装<br />
          {{item.appraisePack}}
        </van-col>
        <van-col class="distribution_first">
          配送满意度
          <br />
          {{ item. appraiseSend}}
        </van-col>
      </van-row>
      <div style="width: 90%; margin: 0 auto 10px; word-wrap: break-word">
        {{ item.appraiseContent }}
      </div>
      <div
        v-if="item.replyContent"
        style="
          width: 85%;
          margin: 0 auto;
          background-color: rgb(245, 245, 245);
          border-radius: 5px;
          padding: 10px;
        "
      >
        <p style="color: grey">商家回复</p>
        <p style="margin-top: 5px; word-wrap: break-word">
          {{ item.replyContent }}
        </p>
      </div>
      <van-button size="small" v-else @click="showPopup(item.id)" class="button"
        >回复评论</van-button
      >
    </div>
    <van-popup v-model="show" position="bottom">
      <van-field
        v-model="reply"
        class="review"
        rows="1"
        type="textarea"
        placeholder="有爱评论，说点好听的~"
      >
        <template #button>
          <van-button @click="getObj" class="release" size="small"
            >发布</van-button
          ></template
        ></van-field
      >
    </van-popup>
  </div>
</template>

<script>
import { getObj, getEvaluate } from "@/api/customerEvaluation";
import { Toast } from "vant";
import qs from "qs";
export default {
  data() {
    return {
      show: false,
      //商品评价数据
      evaluate: {},
      // Release:true
      reply: "",
      user:2,
      arr: [],
      id: 0
    };
  },
  created() {
    // this.getObj();
    this.getEvaluate();
    this.$store.commit("setSession", this.$route.query.session);
    console.log(this.$route, 'route')
  },
  methods: {
    onClickLeft() {},
    showPopup(id) {
      this.show = true;
      this.id = id
    },
    // 商品
    getEvaluate() {
      let data = {
        orderId: "1484408421426954241",
        userId:"1478911948697616349"
      };
      getEvaluate(qs.stringify(data)).then((res) => {
        this.evaluate = res.data.data;
        this.arr = this.evaluate.allOrderGoods
        console.log(res, "商品");
      });
    },

    // 商家回复 
    getObj() {
      getObj(qs.stringify({ id: this.id, replyContent: this.reply })).then(
        (res) => {
          if (res.data.code !== 0) {
            Toast.fail(res.data.msg);
          } else {
            Toast.success(res.data.data);
            this.show = false;
            this.getEvaluate();
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.header_global {
  height: 100%;
  background-color: #f5f5f5;
}
.digital {
  font-size: 50px;
  margin-top: 8px;
  margin-left: 15px;
}
.mainbody {
  background-color: #ffffff;
  height: 85px;
}
.mainbody_first {
  background-color: #f5f5f5;
  margin-left: 3%;
  margin-right: 3%;
  height: 75px;
}
.mainbody_second {
  margin-top: 15px;
  margin-left: 5px;
}
.anonymous_first {
  margin-top: 15px;
  margin-left: 5px;
}

.mainbody_third {
  margin-left: 30px;
  margin-top: 15px;
}
.mainbody_fourth {
  margin-top: 15px;
  margin-left: 18px;
}
.mainbody_fifth {
  margin-top: 15px;
  margin-left: 18px;
}
.middle_comment {
  height: 2.5%;
  margin-top: 10px;
}
.comments {
  color: #a9a9a9;
  margin-left: 10px;
}
.middle {
  background-color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  height: 30%;
  margin-top: 10px;
}
.img_first {
  margin-top: 20px;
  margin-left: 10px;
}
.date {
  color: #a9a9a9;
}
.score_first {
  color: #a9a9a9;
  margin-left: 8px;
  margin-top: 40px;
}

.taste_first {
  color: #a9a9a9;
  margin-left: 30px;
  margin-top: 40px;
}

.pack_first {
  color: #a9a9a9;
  margin-left: 10px;
  margin-top: 40px;
}

.distribution_first {
  color: #a9a9a9;
  margin-left: 10px;
  margin-top: 40px;
}

.button {
  background-color: #ffd700;
  margin-top: 60px;
  margin-left: 270px;
  padding: 5px;
  margin-right: 10px;
}
/deep/.review {
  padding: 0 16px;
}
.release {
  background-color: #ffd700;
  padding: 15px;
}
/deep/ .van-field--min-height .van-field__control {
  min-height: 32px;
  margin: 5px 0;
}
/deep/ .van-field__control {
  background: #F6F6F6;
  border-radius: 16px 0 0 16px;
  padding-left: 15px;
  padding-top: 5px;
}
/deep/ .van-field__button {
  padding: 0;
  .van-button--default {
    border-radius: 0 16px 16px 0;
  }
}
</style>
